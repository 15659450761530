html {
  background-color: white;
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

.toolbar {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  z-index: 9999;
}

.toolbar > .button {
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 3px;
  cursor: pointer;
}

.toolbar > .button:hover {
  background-color: #cccccc;
}

.stepIcon {
  fill: #3c9ee5;
  font-size: 0.8em;
  text-anchor: middle;
  font-weight: 200;
}

.stepIconEnded {
  fill: darkgray;
  font-size: 0.8em;
  text-anchor: middle;
  font-weight: 200;
}

.stepIconPending {
  fill: green;
  font-size: 0.8em;
  text-anchor: middle;
  font-weight: 200;
}

.node {
  /* ノードの縦幅 */
  height: 50px;
  /* ノードの横幅 */
  width: 180px;
  border-radius: 3px;
  cursor: pointer;
}

.fullnode {
  height: 390px;
  width: 280px;
  border-radius: 3px;
  cursor: inherit;
}

.node-container {
  height: 78px;
  display: flex;
  flex-direction: column;
  text-align: left;
  /* Glass like background */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(7.9px);
  -webkit-backdrop-filter: blur(7.9px);
  color: white;
  border-radius: 2.5px;
  transition: 0.2s, transform 300ms;
}

.node-default-container-color {
  border: 1px solid rgb(241, 241, 241);
  background: rgba(255, 255, 255, 0.3);
}

.node-sub-container-color {
  border: 1px solid rgba(241, 241, 241, 0.278);
  background: rgba(0, 0, 0, 0.533);
}

.node-container:hover {
  transform: scale(1.1);
}

.node-status {
  text-align: center;
  font-size: small;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.node-status-kgi {
  background: rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgb(149, 149, 149);
}

.node-status-kpi {
  background: rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgb(149, 149, 149);
}

.node-status-subtree {
  background: rgba(0, 0, 0, 0.637);
  border-bottom: 1px solid rgba(149, 149, 149, 0.548);
}
.react-flow__pane {
  cursor: default;
}
.react-flow__panel {
  visibility: hidden;
}
